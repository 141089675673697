




































































































import { Component, Vue } from 'vue-property-decorator';
import DeleteCustomInvestorGoalDistributeBalanceViewModel
  from '@/vue-app/view-models/components/goals-dashboard/delete-goals/delete-custom-investor-goal-distribute-balance-view-model';

@Component({ name: 'DeleteCustomInvestorGoalDistributeBalance' })
export default class DeleteCustomInvestorGoalDistributeBalance extends Vue {
  delete_custom_goal_distribute_balance_model = Vue.observable(
    new DeleteCustomInvestorGoalDistributeBalanceViewModel(this),
  );

  created() {
    this.delete_custom_goal_distribute_balance_model.initialize();
  }
}
