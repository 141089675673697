import Vue from 'vue';
import TYPES from '@/types';

// Application
import TransferBalanceCustomInvestorGoalCommand
  from '@/modules/flagship/custom-investor-goal/application/command/transfer-balance-custom-investor-goal-command';
import GetCustomerInvestorGoalBalanceQuery
  from '@/modules/flagship/customer-investor-goal-balance/application/query/get-customer-investor-goal-balance';
import DeleteCustomInvestorGoalStateManagerService
  from '@/modules/flagship/custom-investor-goal/application/service/delete-custom-investor-goal-state-manager-service';

// Domain
import DeleteCustomInvestorGoalState
  from '@/modules/flagship/custom-investor-goal/domain/state/delete-custom-investor-goal-state';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { currencyFormat } from '@/vue-app/utils/currency';

type ArrayLinkedGoals = {
  investor_goal_id: string;
  name: string;
  label: string;
  icon: string;
  assigned_amount: number;
  alt: string;
  assigned_amount_field: string;
}

export default class DeleteCustomInvestorGoalDistributeBalanceViewModel {
  @Inject(TYPES.TRANSFER_BALANCE_CUSTOM_INVESTOR_GOAL_COMMAND)
  private readonly transfer_balance_custom_investor_goal!: TransferBalanceCustomInvestorGoalCommand;

  @Inject(TYPES.GET_CUSTOMER_INVESTOR_GOAL_BALANCE_QUERY)
  private readonly get_customer_investor_goal_balance_query!: GetCustomerInvestorGoalBalanceQuery;

  @Inject(TYPES.DELETE_CUSTOM_INVESTOR_GOAL_STATE_MANAGER_SERVICE)
  private readonly state_manager_service!: DeleteCustomInvestorGoalStateManagerService;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  readonly i18n_namespace = 'components.goals-dashboard.delete-custom-investor-goal-distribute-balance';

  linked_goals: Array<ArrayLinkedGoals> = [];

  state: DeleteCustomInvestorGoalState;

  deposit_amount = 0;

  unassigned_balance = 0;

  is_disabled = false;

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  view!: Vue;

  constructor(view: Vue, state?: DeleteCustomInvestorGoalState) {
    this.view = view;
    this.state = state || this.state_manager_service.get();
  }

  getAmountFormatted = (amount: number) => (currencyFormat(amount));

  setUnassignedAmount = () => {
    this.linked_goals.push({
      investor_goal_id: '',
      name: 'unassigned',
      label: `${this.translate('label')} ${
        this.state.custom_investor_goal.associated_product_name
      }`,
      icon: 'noun-coins.svg',
      assigned_amount: this.unassigned_balance,
      alt: 'alts.unassigned',
      assigned_amount_field: '',
    });
  }

  initialize = async () => {
    this.state.is_loading = true;
    this.deposit_amount = this.state.custom_investor_goal.active_goal_amount;
    await this.loadCustomerInvestorGoalBalance(
      this.state.custom_investor_goal.associated_product_id,
    );
    this.setUnassignedAmount();
    this.state.is_loading = false;
  }

  transferBalanceCustomInvestorGoal = async () => {
    try {
      await this.transfer_balance_custom_investor_goal.execute(
        this.state.custom_investor_goal.custom_investor_goal_id,
      );
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.transfer_balance_custom_investor_goal'));
    }
  }

  loadCustomerInvestorGoalBalance = async (associated_product_id: string) => {
    try {
      const { unassigned_balance } = await this.get_customer_investor_goal_balance_query
        .execute({ investment_product_id: associated_product_id });
      this.unassigned_balance = unassigned_balance;
    } catch (error) {
      if (JSON.parse(error).status_code !== 404) {
        this.message_notifier.showErrorNotification(this.translate('errors.load_customer_investor_goal_balance'));
      }
    }
  }

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  nextStep = async () => {
    // await this.transferBalanceCustomInvestorGoal();
    this.view.$emit('nextStep');
  }
}
